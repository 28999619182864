import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../common/localization/localize";
import classes from "./quote.module.css";

export const Quote: FC = () => (
  <p className={classes.quote}>
    <blockquote>{useLocalized("awardZabrze_text2")}</blockquote>
  </p>
);
