import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { AwardZabrze } from "../components/award_zabrze/award-zabrze";
import { Quote } from "../components/award_zabrze/quote";

export const AwardZabrzeEntrypoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("awardZabrze_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <p>{useLocalized("awardZabrze_text1")}</p>
      <Quote />
      <p>{useLocalized("awardZabrze_text3")}</p>
      <AwardZabrze />
    </>
  );
};
