import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../common/localization/localize";
import classes from "./award-zabrze.module.css";

export const AwardZabrze: FC = () => (
  <div className={classes.award}>
    <img
      src={useLocalized("awardZabrze_image")}
      alt={useLocalized("awardZabrze_image_alt")}
    />
  </div>
);
